import { all, fork } from 'redux-saga/effects'

import auth from 'domain/sagas/auth.saga';
import billing from 'domain/sagas/billing.saga';
import email from 'domain/sagas/email.saga';
import hashtags from 'domain/sagas/hashtags.saga';
import cities from 'domain/sagas/city.saga';
import account from 'domain/sagas/account.saga';
import restaurants from 'domain/sagas/restaurant.saga';
import stocks from 'domain/sagas/stock.saga';
import secretShopper from 'domain/sagas/shopper.saga';
import owner from 'domain/sagas/owner.saga';
import ownerRestaurant from 'domain/sagas/owner.restaurant.saga';
import norr from 'domain/sagas/norr.saga';
import favorites from 'domain/sagas/favorites.saga';
import notifications from 'domain/sagas/notifications.saga';
import coupon from 'domain/sagas/coupon.saga';
import main from 'domain/sagas/main.saga';


export default function* rootSaga() {
  yield all(
    [
      fork(auth),
      fork(billing),
      fork(email),
      fork(cities),
      fork(account),
      fork(restaurants),
      fork(stocks),
      fork(main),
      fork(secretShopper),
      fork(owner),
      fork(ownerRestaurant),
      fork(hashtags),
      fork(norr),
      fork(favorites),
      fork(notifications),
      fork(coupon)
    ])
}