import React from "react";
import Header from "./layouts/header";
import Filter from "./layouts/filter";
import Banner from "./layouts/banner";
import Events from "./layouts/events";
import RecentViews from "./layouts/recentViews";
import About from "./layouts/about";
import Footer from "./layouts/footer";

import { WrapperProgress } from "components/wrapperProgress";

export const StartPage: React.FC = (props: any) => {

  return (
    <WrapperProgress timer={500}>
      <div className='main_container'>
        <Header />

        <Filter />

        <Banner />

        <Events />

        <RecentViews />

        <About />

        <Footer />
      </div>
    </WrapperProgress>
  )
}

export default StartPage;