import { combineReducers } from 'redux';
import auth from './auth.reduce';
import email from './email.reduce';
import account from './account.reduce';
import restaurants from './restaurant.reduce';
import cities from './city.reduce';
import stocks from './stock.reduce';
import filters from './filter.reduce';
import favorites from './favorites.reduce';
import hashtags from './hashtags.reduce';
import news from './news.reduce';
import secretShopper from './secretShopper.reduce';
import owner from './owner.reduce';
import norr from './norr.reduce';
import other from './other.reduce';
import coupon from './coupon.reduce';
import main from './main.reduce';

const rootReducer = combineReducers({
  auth,
  account,
  cities,
  stocks,
  secretShopper,
  filters,
  favorites,
  hashtags,
  news,
  restaurants,
  email,
  owner,
  norr,
  other,
  coupon,
  main
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;